import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorNavigation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 38 38"
    {...props}
  >
    <path
      fill="#fff"
      d="M31.75 1C34.65 1 37 3.35 37 6.25v25.5c0 2.9-2.35 5.25-5.25 5.25H6.25A5.25 5.25 0 0 1 1 31.75V6.25C1 3.35 3.35 1 6.25 1h25.5Z"
    />
    <path
      stroke="#CFD4DE"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M14 1H6.25A5.25 5.25 0 0 0 1 6.25v25.5C1 34.65 3.35 37 6.25 37H14m0-36h17.75C34.65 1 37 3.35 37 6.25v25.5c0 2.9-2.35 5.25-5.25 5.25H14m0-36v36"
    />
    <rect width={14.625} height={10.362} x={17.875} y={6} fill="#CFD4DE" rx={2.5} />
    <path
      fill="#EAE9FE"
      stroke="#CFD4DE"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M1 31.75C1 34.65 3.35 37 6.25 37H14V1H6.25A5.25 5.25 0 0 0 1 6.25v25.5Z"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.13}
      d="M5.66 8.09H9.5"
    />
    <path
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.13}
      d="M5.66 14.09H9.5M5.66 20.09H9.5"
    />
    <path
      stroke="#CFD4DE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.13}
      d="M19.351 20.836h11.68M19.351 26.501h11.68"
    />
  </svg>
);
export default SvgNoCodeColorNavigation;

import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeNavigation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 38 38"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      fill="#fff"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M37 6.25C37 3.35 34.65 1 31.75 1H6.25A5.25 5.25 0 0 0 1 6.25v25.5C1 34.65 3.35 37 6.25 37h25.5c2.9 0 5.25-2.35 5.25-5.25V6.25Z"
    />
    <path strokeLinecap="round" strokeLinejoin="round" d="m25.96 6.94 3.783 3.783-3.689 3.689" />
    <path
      strokeLinecap="round"
      strokeMiterlimit={10}
      d="M7.841 10.664h11.481M7.841 19.91h22.14M7.841 27.93h22.14"
    />
  </svg>
);
export default SvgNoCodeNavigation;
